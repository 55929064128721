<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-form inline>
            <b-form-group
              label="Time range"
              label-sr-only
            >
              <date-range-picker
                v-model="timeRange"
                :selectable-ranges="['last3Days', 'last7Days', 'last14Days', 'last30Days', 'last60Days', 'last90Days', 'last180Days', 'last365Days']"
                :max-selectable-days="365"
              />

              <template v-slot:description>
                <div
                  v-b-popover.hover.bottom="`If time interval is ${maxDaysForDetailedData} days or less, detailed data points with a resolution of 10 minutes are displayed. For larger time intervals, the data points are aggregated to the max. daily value.`"
                  title="Data points resolution"
                >
                  <feather-icon icon="HelpCircleIcon" />

                  <span
                    v-if="useDetailedFileReplicationData"
                    class="align-middle"
                  >
                    Detailed data points
                  </span>
                  <span
                    v-else
                    class="align-middle"
                  >
                    Aggregated data points
                  </span>
                </div>
              </template>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                v-model="compactMode"
              >
                Compact mode
              </b-form-checkbox>
            </b-form-group></b-form>

        </b-card>
      </b-col>
    </b-row>

    <b-card>
      <apexchart
        type="line"
        :options="chartOptions.fileReplicationInboundChartOptions"
        :series="[inboundNetworkSeries, inboundPreCompSeries, inboundPostSynteticOptimSeries, inboundPostFilteredSeries, inboundPostLowSeries, inboundPostCompressedSeries]"
        @click="chartClickHandler"
      />
    </b-card>

    <b-card>
      <apexchart
        type="line"
        :options="chartOptions.fileReplicationOutboundChartOptions"
        :series="[outboundNetworkSeries, outboundPreCompSeries, outboundPostSynteticOptimSeries, outboundPostFilteredSeries, outboundPostLowSeries, outboundPostCompressedSeries]"
        @click="chartClickHandler"
      />
    </b-card>

    <DataDomainDetailFileReplicationSidebar
      ref="fileReplicationSidebar"
      :file-replication-inbound-data="selectedFileReplicationInboundData"
      :file-replication-outbound-data="selectedFileReplicationOutboundData"
      :timezone="asup.timezone"
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BFormCheckbox, VBPopover, BForm, BFormGroup,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import DateRangePicker from '@/components/dateRangePicker/DateRangePicker.vue'
import DataDomainService from '@/service/datadomain.service'
import moment from '@/libs/moment'

import DataDomainDetailFileReplicationSidebar from './components/DataDomainDetailFileReplicationSidebar.vue'

const prettyBytes = require('@/utils/pretty-bytes')

export default {
  components: {
    DateRangePicker,
    BRow,
    BCol,
    BCard,
    apexchart: VueApexCharts,
    BFormCheckbox,
    BForm,
    BFormGroup,
    DataDomainDetailFileReplicationSidebar,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      compactMode: false,
      chartHeightNormalMode: 350,
      chartHeightCompactMode: 150,
      maxDaysForDetailedData: 30,
      timeRange: {
        range: 'last3Days',
      },
      statistic: [],
      selectedFileReplicationInboundData: {},
      selectedFileReplicationOutboundData: {},
      chartOptions: {
        fileReplicationInboundChartOptions: {
          chart: {
            id: 'fileReplicationInboundChart',
            group: 'fileReplication',
            type: 'line',
            stacked: false,
            height: 350,
            zoom: {
              autoScaleYaxis: true,
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          stroke: {
            show: true,
            curve: 'straight',
            lineCap: 'butt',
            width: 1.5,
          },
          dataLabels: {
            enabled: false,
          },
          markers: {
            size: 0,
          },
          title: {
            text: 'File Replication Inbound',
            align: 'left',
          },
          yaxis: {
            labels: {
              formatter(val) {
                return `${(val / 1024 / 1024 / 1024).toFixed(0)} GiB`
              },
              minWidth: 0,
            },
            title: {
              text: 'GiB / 10 minutes',
            },
          },
          xaxis: {
            type: 'datetime',
          },
          tooltip: {
            shared: true,
            y: {
              formatter(val) {
                if (val) {
                  return prettyBytes(val, { binary: true })
                }

                return null
              },
            },
            x: {
              formatter(val) {
                return moment.utc(val).format('L LT')
              },
            },
          },
        },
        fileReplicationOutboundChartOptions: {
          chart: {
            id: 'fileReplicationOutboundChart',
            group: 'fileReplication',
            type: 'line',
            stacked: false,
            height: 350,
            zoom: {
              autoScaleYaxis: true,
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          stroke: {
            show: true,
            curve: 'straight',
            lineCap: 'butt',
            width: 1.5,
          },
          dataLabels: {
            enabled: false,
          },
          markers: {
            size: 0,
          },
          title: {
            text: 'File Replication Outbound',
            align: 'left',
          },
          yaxis: {
            labels: {
              formatter(val) {
                return `${(val / 1024 / 1024 / 1024).toFixed(0)} GiB`
              },
              minWidth: 0,
            },
            title: {
              text: 'GiB / 10 minutes',
            },
          },
          xaxis: {
            type: 'datetime',
          },
          tooltip: {
            shared: true,
            y: {
              formatter(val) {
                if (val) {
                  return prettyBytes(val, { binary: true })
                }

                return null
              },
            },
            x: {
              formatter(val) {
                return moment.utc(val).format('L LT')
              },
            },
          },
        },
      },
    }
  },
  computed: {
    chartIds() {
      const chartIds = []
      // eslint-disable-next-line no-unused-vars
      Object.entries(this.chartOptions).forEach(([key, chartOption]) => {
        chartIds.push(chartOption.chart.id)
      })

      return chartIds
    },
    oldestStatistic() {
      return this.statistic
        .concat()
        .sort((a, b) => ((moment.utc(a.timestamp) > moment.utc((b.timestamp)) ? -1 : 1)))[0]
    },
    chartHeight() {
      if (this.compactMode === true) {
        return this.chartHeightCompactMode
      }

      return this.chartHeightNormalMode
    },
    useDetailedFileReplicationData() {
      /* Detailed file replication data (datapoints all 10 minutes) is only available for up to X days timerange */
      return moment(this.timeRange.endDate).diff(this.timeRange.startDate, 'days') <= this.maxDaysForDetailedData
    },
    fileReplicationData() {
      if (this.useDetailedFileReplicationData) {
        return this.statistic.flatMap(x => x.fileReplications).sort((a, b) => (moment.utc(a.time) > moment.utc(b.time) ? 1 : -1))
      }

      /* FileReplicationInboundMax and FileReplicationOutboundMax data (> X day timerange) has no time property, so we use the time property of the statistic entry itself (for every day) */
      const result = []
      for (let i = 0; i < this.statistic.length; i += 1) {
        const inboundData = this.statistic[i].fileReplicationInboundMax
        const outboundData = this.statistic[i].fileReplicationOutboundMax

        if (inboundData) {
          inboundData.time = this.statistic[i].timestamp
          result.push(inboundData)
        }

        if (outboundData) {
          outboundData.time = this.statistic[i].timestamp
          result.push(outboundData)
        }
      }

      return result.sort((a, b) => (moment.utc(a.time) > moment.utc(b.time) ? 1 : -1))
    },
    fileReplicationInboundData() {
      return this.fileReplicationData.filter(x => x.direction === 0)
    },
    fileReplicationOutboundData() {
      return this.fileReplicationData.filter(x => x.direction === 1)
    },
    inboundPreCompSeries() {
      return {
        name: 'PreComp',
        data: this.fileReplicationInboundData.map(x => [this.adjustByTimezone(this.$moment.utc(x.time)).toISOString(), x.preComp]),
      }
    },
    inboundNetworkSeries() {
      return {
        name: 'Network',
        data: this.fileReplicationInboundData.map(x => [this.adjustByTimezone(this.$moment.utc(x.time)).toISOString(), x.network]),
      }
    },
    inboundPostSynteticOptimSeries() {
      return {
        name: 'Post syntetic optim',
        data: this.fileReplicationInboundData.map(x => [this.adjustByTimezone(this.$moment.utc(x.time)).toISOString(), x.postSynteticOptim]),
      }
    },
    inboundPostFilteredSeries() {
      return {
        name: 'Post filtered',
        data: this.fileReplicationInboundData.map(x => [this.adjustByTimezone(this.$moment.utc(x.time)).toISOString(), x.postFiltered]),
      }
    },
    inboundPostLowSeries() {
      return {
        name: 'Post low',
        data: this.fileReplicationInboundData.map(x => [this.adjustByTimezone(this.$moment.utc(x.time)).toISOString(), x.postLow]),
      }
    },
    inboundPostCompressedSeries() {
      return {
        name: 'Post compressed',
        data: this.fileReplicationInboundData.map(x => [this.adjustByTimezone(this.$moment.utc(x.time)).toISOString(), x.postComp]),
      }
    },
    outboundPreCompSeries() {
      return {
        name: 'PreComp',
        data: this.fileReplicationOutboundData.map(x => [this.adjustByTimezone(this.$moment.utc(x.time)).toISOString(), x.preComp]),
      }
    },
    outboundNetworkSeries() {
      return {
        name: 'Network',
        data: this.fileReplicationOutboundData.map(x => [this.adjustByTimezone(this.$moment.utc(x.time)).toISOString(), x.network]),
      }
    },
    outboundPostSynteticOptimSeries() {
      return {
        name: 'Post syntetic optim',
        data: this.fileReplicationOutboundData.map(x => [this.adjustByTimezone(this.$moment.utc(x.time)).toISOString(), x.postSynteticOptim]),
      }
    },
    outboundPostFilteredSeries() {
      return {
        name: 'Post filtered',
        data: this.fileReplicationOutboundData.map(x => [this.adjustByTimezone(this.$moment.utc(x.time)).toISOString(), x.postFiltered]),
      }
    },
    outboundPostLowSeries() {
      return {
        name: 'Post low',
        data: this.fileReplicationOutboundData.map(x => [this.adjustByTimezone(this.$moment.utc(x.time)).toISOString(), x.postLow]),
      }
    },
    outboundPostCompressedSeries() {
      return {
        name: 'Post compressed',
        data: this.fileReplicationOutboundData.map(x => [this.adjustByTimezone(this.$moment.utc(x.time)).toISOString(), x.postComp]),
      }
    },
  },
  watch: {
    timeRange(val) {
      this.loadStatistic(val)
      this.updateCharts()
    },
    compactMode() {
      this.updateCharts()
    },
  },
  mounted() {
    this.loadStatistic()
  },
  methods: {
    adjustByTimezone(dateTime) {
      const offset = this.$moment.tz.zone(this.asup.timezone).utcOffset(dateTime)
      return dateTime.clone().subtract(offset, 'minutes')
    },
    updateCharts() {
      for (let i = 0; i < this.chartIds.length; i += 1) {
        const { opts } = this.$apexcharts.getChartByID(this.chartIds[i])

        // Update height
        opts.chart = this.$apexcharts.merge(opts, {
          height: this.chartHeight,
        })

        for (let j = 0; j < opts.yaxis.length; j += 1) {
        // Update all Y axis tick amount to prevent overlapping labels
          opts.yaxis[j] = this.$apexcharts.merge(opts.yaxis[j], {
            tickAmount: this.compactMode === true ? 2 : 10,
            title: {
              style: {
                fontSize: this.compactMode === true ? '8px' : '12px',
              },
            },
          })
        }

        let xAxisMax = moment(this.timeRange.endDate).endOf('day')
        if (this.oldestStatistic) {
          const os = this.oldestStatistic
            .fileReplications
            .sort((a, b) => ((moment.utc(a.time) > moment.utc((b.time)) ? -1 : 1)))[0].time

          xAxisMax = this.adjustByTimezone(this.$moment.utc(os))
        }

        // Update X axis with new time range
        opts.xaxis = this.$apexcharts.merge(opts.xaxis, {
          min: this.adjustByTimezone(moment(this.timeRange.startDate)).startOf('day').valueOf(),
          max: xAxisMax.valueOf(),
        })

        this.$apexcharts.exec(this.chartIds[i], 'updateOptions', opts)
      }
    },
    loadStatistic() {
      const includeFields = []

      /*
       Fililerplication data (datapoint all 10 minutes) is only available for max. X days timerange.
       If more than X days are selected, we use FileReplicationInboundMax and FileReplicationOutboundMax data which aggregates all 10minute datapoints to one datapoint for every day
       */
      if (this.useDetailedFileReplicationData) {
        includeFields.push('FileReplications')
      } else {
        includeFields.push('FileReplicationInboundMax')
        includeFields.push('FileReplicationOutboundMax')
      }

      /*
        File Replication data is stored in child list of statistic data. File replication data of e.g. yesterday may be contained in statistics of today.
        So we need to query statistic data one day before and after the range
        */
      DataDomainService.getStatisticListAsync(this.asup.id, {
        from: moment(this.timeRange.startDate).subtract(1, 'days').startOf('day').format('YYYY-MM-DD'),
        to: moment(this.timeRange.endDate).add(1, 'days').endOf('day').format('YYYY-MM-DD'),
        include: includeFields,
        maxResultCount: 1000,
      }, { disableTenantFilter: true })
        .then(result => {
          this.statistic = []
          const processedDays = {}
          for (let i = 0; i < result.items.length; i += 1) {
            const day = moment(result.items[i].timestamp).startOf('day').toISOString()
            if (!processedDays[day]) {
              this.statistic.push(result.items[i])
              processedDays[day] = true
            }
          }

          this.updateCharts()
        })
    },
    // eslint-disable-next-line no-unused-vars
    chartClickHandler(event, chartContext, config) {
      if (chartContext.zoomPanSelection.startX !== 0) { // check if chart is not in zoomPan mode
        if (config.dataPointIndex !== -1) {
          this.selectedFileReplicationInboundData = this.fileReplicationInboundData[config.dataPointIndex]
          this.selectedFileReplicationOutboundData = this.fileReplicationOutboundData[config.dataPointIndex]
          this.$refs.fileReplicationSidebar.show()
        }
      }
    },
  },
}

</script>

<style scoped>
  .form-inline {
    place-items: flex-start
  }

  .form-group {
    margin-right: 15px;
  }
</style>
