<template>
  <div>
    <b-sidebar
      id="ddDetailFileReplicationSidebar"
      v-model="isShow"
      :title="fileReplicationInboundData.time | formatDateTimeTz(timezone)"
      shadow
      backdrop
      width="400px"
    >
      <div class="px-1 py-1">
        <h4>Inbound</h4>
        <table class="table table-sm table-borderless">
          <tbody>
            <tr>
              <th>Network</th>
              <td>{{ fileReplicationInboundData.network | prettyBytes({binary: true}) }}</td>
            </tr>
            <tr>
              <th>Post low</th>
              <td>{{ fileReplicationInboundData.postLow | prettyBytes({binary: true}) }}</td>
            </tr>
            <tr>
              <th>Post filtered</th>
              <td>{{ fileReplicationInboundData.postFiltered | prettyBytes({binary: true}) }}</td>
            </tr>
            <tr>
              <th>Post syntetic optim</th>
              <td>{{ fileReplicationInboundData.postSynteticOptim | prettyBytes({binary: true}) }}</td>
            </tr>
            <tr>
              <th>Pre compression</th>
              <td>{{ fileReplicationInboundData.preComp | prettyBytes({binary: true}) }}</td>
            </tr>
            <tr>
              <th>Post compression</th>
              <td>{{ fileReplicationInboundData.postComp | prettyBytes({binary: true}) }}</td>
            </tr>
          </tbody>
        </table>

        <h4>Outbound</h4>
        <table class="table table-sm table-borderless">
          <tbody>
            <tr>
              <th>Network</th>
              <td>{{ fileReplicationOutboundData.network | prettyBytes({binary: true}) }}</td>
            </tr>
            <tr>
              <th>Post low</th>
              <td>{{ fileReplicationOutboundData.postLow | prettyBytes({binary: true}) }}</td>
            </tr>
            <tr>
              <th>Post filtered</th>
              <td>{{ fileReplicationOutboundData.postFiltered | prettyBytes({binary: true}) }}</td>
            </tr>
            <tr>
              <th>Post syntetic optim</th>
              <td>{{ fileReplicationOutboundData.postSynteticOptim | prettyBytes({binary: true}) }}</td>
            </tr>
            <tr>
              <th>Pre compression</th>
              <td>{{ fileReplicationOutboundData.preComp | prettyBytes({binary: true}) }}</td>
            </tr>
            <tr>
              <th>Post compression</th>
              <td>{{ fileReplicationOutboundData.postComp | prettyBytes({binary: true}) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-sidebar>
  </div>
</template>

<script>

import { BSidebar } from 'bootstrap-vue'

export default {
  components: {
    BSidebar,
  },
  props: {
    fileReplicationInboundData: {
      type: Object,
      default: () => {},
    },
    fileReplicationOutboundData: {
      type: Object,
      default: () => {},
    },
    timezone: {
      type: String,
      default: 'Etc/UTC',
    },
  },
  data() {
    return {
      isShow: false,
    }
  },
  methods: {
    show() {
      this.isShow = true
    },
  },
}

</script>
